import React from 'react';
import cx from 'classnames';

import './ValueWithChange.scss';

export const ValueWithChange = ({ value, changePercent }) => {
  if (!value && value !== 0) return '';

  return (
    <div className="custom-cell__value-with-change">
      {value}{' '}
      {!!changePercent || changePercent === 0 ? (
        <span
          className={cx('value-change-percent', {
            positive: changePercent > 0,
            negative: changePercent < 0,
          })}
        >
          ({changePercent >= 0 ? `+${changePercent}` : `-${changePercent}`}%)
        </span>
      ) : null}
    </div>
  );
};
